<template>
  <div class="col-12">
    <div class="card card-body">
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        الاسم
                    </th>
                    <th>
                        الهاتف
                    </th>
                    <th>
                        كود التفعيل
                    </th>
                    <th>
                        كلمة المرور
                    </th>
                    <th>
                        تاريخ الانضمام
                    </th>
                    <th>
                        حالة التفعيل
                    </th>
                    <th>
                        عدد الحجز المسموح
                    </th>
                </thead>
                <tbody>
                    <tr v-for="user in users" :key="user._id">
                        <td>
                            {{ user.name }} <span v-if="!user.activated">{{ user.otp }}</span>
                        </td>
                        <td>
                            {{ user.phone }}
                        </td>
                        <td>
                            {{ user.otp }}
                        </td>
                        <td>
                            <input type="text" v-model="user.password" style="border: none;" readonly>
                        </td>
                        <td>
                            {{ user.joindate }}
                        </td>
                        <td>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input type="checkbox" class="form-check-input" v-model="user.status" :value="true" @change="editUser(user._id, 'status', user.status)">
                                الحساب
                                فعال
                              </label>
                            </div>
                        </td>
                        <td>
                            <input type="number" v-model="user.limit_book" style="width: 50px; border: none;" @change="editUser(user._id, 'limit_book', user.limit_book)">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            users: []
        }
    },
    created(){
        this.getUsers()
    },
    methods: {
        getUsers(){
            var g = this;
            $.post(api + '/admin/users', {
                jwt: g.user.jwt
            }).then(function(r){
                g.users = r.response
            })
        },
        editUser(id, input, value){
            var g = this;
            $.post(api + '/admin/edit-user', {
                jwt: g.user.jwt,
                id: id,
                input: input,
                value: value
            }).then(function(r){
                g.getUsers()
            })
        }
    }
}
</script>

<style>

</style>